<template>
  <el-dialog @close="close('ruleForm')"
             :visible.sync="dialogVisible"
             :show-close="false">
    <div slot="title"
         class="left_top">
      <div class="left_icon"><span></span> <span>新建跟进的企业</span></div>
      <div class="right_icon"><span></span></div>
    </div>
    <el-form label-position="top"
             width="900px"
             :model="ruleForm"
             :rules="rules"
             ref="ruleForm"
             class="ruleForm"
             label-width="80px">
      <el-form-item label="客户名称"
                    prop="name">
        <el-input v-model="ruleForm.name"></el-input>
      </el-form-item>

      <el-form-item label="统一信用编码"
                    prop="idCardNumber">
        <el-input v-model="ruleForm.idCardNumber"></el-input>
      </el-form-item>

      <el-form-item label="经营范围"
                    prop="range">
        <el-input v-model="ruleForm.range"></el-input>
      </el-form-item>

      <el-form-item label="客户类型">
        <el-select style="width:100%"
                   v-model="ruleForm.type"
                   placeholder="请选择">
          <el-option v-for="item in typeList"
                     :key="item.label"
                     :label="item.label"
                     :value="item.label">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="客户地址"
                    prop="address">
        <el-input v-model="ruleForm.address"></el-input>
      </el-form-item>

      <el-form-item label="行业"
                    prop="industry">
        <el-input v-model="ruleForm.industry"></el-input>
      </el-form-item>

      <el-form-item label="联系人"
                    prop="contacts">
        <el-input v-model="ruleForm.contacts"></el-input>
      </el-form-item>

      <el-form-item label="联系人电话"
                    prop="contactsPhone">
        <el-input v-model="ruleForm.contactsPhone"></el-input>
      </el-form-item>

      <el-form-item label="邮箱"
                    prop="contactsMail">
        <el-input v-model="ruleForm.contactsMail"></el-input>
      </el-form-item>

      <el-form-item label="费率"
                    prop="rate">
        <el-input v-model="ruleForm.rate"
                  placeholder="依次输入陕西,山东,江西的费率"></el-input>
      </el-form-item>

      <el-form-item label="备注"
                    prop="remark">
        <el-input type="textarea"
                  v-model="ruleForm.remark"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer"
         class="dialog-footer"
         style="text-align:center">
      <el-button type="primary"
                 @click="submitForm('ruleForm')">确 定</el-button>
      <el-button @click="dialogVisible = false">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { api } from '/src/api/base';
//新增接口
export const FollowEnterpriseAdd = api('/followEnterprise')(
  'followEnterprise.add.json'
);
//查询列表接口
export const FollowEnterpriseList = api('/followEnterprise')(
  'followEnterprise.list.json'
);
//查询详情接口
export const FollowEnterpriseDetail = api('/followEnterprise')(
  'followEnterprise.describe.json'
);
//编辑接口
export const FollowEnterpriseEdit = api('/followEnterprise')(
  'followEnterprise.edit.json'
);
//删除接口
export const FollowEnterpriseDel = api('/followEnterprise')(
  'followEnterprise.del.json'
);
export default {
  //   components: {
  //   Upload: () => import("/src/components/upload.vue")
  // },
  data () {
    return {
      typeList: [
        {
          value: '1',
          label: '一般纳税人'
        },
        {
          value: '2',
          label: '小规模'
        }
      ],
      ruleForm: {
        name: '',

        idCardNumber: '',

        range: '',

        type: '',

        address: '',

        industry: '',

        contacts: '',

        contactsPhone: '',

        contactsMail: '',

        rate: '',

        remark: ''
      },
      rules: {
        name: [{ required: true, message: '请输入客户名称', trigger: 'blur' }],
        idCardNumber: [
          { required: true, message: '请输入统一信用编码', trigger: 'blur' }
        ],
        range: [],
        type: [],
        address: [
          { required: true, message: '请输入客户地址', trigger: 'blur' }
        ],
        industry: [],

        contacts: [
          { required: true, message: '请输入联系人姓名', trigger: 'change' }
        ],

        contactsPhone: [
          { required: true, message: '请输入联系人电话', trigger: 'change' }
        ],

        contactsMail: [],

        rate: [],

        remark: []
      }
    };
  },
  props: {
    dialogVisible: Boolean
  },
  methods: {
    submitForm (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          // alert("submit!");
          FollowEnterpriseAdd(this.ruleForm).then(res => {
            console.log(res);
            this.closeDialog();
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    close (formName) {
      this.$refs[formName].resetFields();
      this.closeDialog();
    },
    closeDialog () {
      this.$emit('update:dialogVisible', false);
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  padding-bottom: 15px;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ruleForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.el-form-item {
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
  width: 49%;
}

.el-form-item__label {
  padding-bottom: 0 !important;
}
</style>
